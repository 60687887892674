.query-results {
  position: relative;
  &--modified {
    tbody {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &__stale-message-container {
    position: absolute;
    box-sizing: border-box;
    top: 10%;
    left: 30%;
    background-color: white;
    padding: 20px;
    box-shadow: 1px 2px 15px 0px black;
  }

  &__popup {
    div:first-of-type {
      padding-bottom: 10px;
    }

    div {
      cursor: pointer;
    }
  }
}
