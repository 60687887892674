.drag-drop {
  &__text {
    padding-bottom: 16px;
  }
  &__button .MuiButton-label {
    font-weight: bold;
  }
}

.file-upload {
  padding: 16px 16px 0 16px;
  &__text {
    margin-top: 16px;
    > span {
      margin-right: 16px;
    }
  }
  &__button .MuiButton-label {
    font-weight: bold;
  }
}

.file-details {
  &__container {
    padding: 16px;
  }
}

.row {
  min-width: 190px;
  p {
    padding: 0px 10px;
    margin-bottom: 4px;
  }
  &--success {
    background-color: #00941233;
  }
  &--failed {
    background-color: #ff220033;
  }
}
