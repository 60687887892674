@mixin react-autosuggest {
  .react-autosuggest {
    &__container {
      position: relative;
      background-color: #fff;
      input {
        border-radius: 5px;
        border: 1px solid black;
        width: 100%;
        height: 30px;
        padding-left: 5px;
      }
    }

    &__suggestions-container {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }

    &__suggestions-list {
      list-style: none;
      padding: 0;
    }

    &__section-title {
      font-size: 10px;
      color: #808080;
      padding: 10px 0 10px 5px;
    }

    &__suggestion {
      color: #000000;
      font-size: 14px;
      list-style: none;
      border-bottom: 1px solid black;
      padding: 5px 0 5px 15px;
      cursor: pointer;
      border-bottom: 1px solid #f2f2f2;
      &:hover {
        background: #f7f7f7;
      }
    }
  }
}
