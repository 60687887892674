.sort-ordering {
  padding: 10px;
  margin: 0 20px 10px 20px;
  border: 1px solid #dbdbdc;

  div {
    align-items: center;

    .header {
      margin: 0;
      padding: 0 10px;
      width: 8%;
    }

    .button {
      margin-right: 10px;
      height: 40px;
    }
  }
}
