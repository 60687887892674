.sort-condition {
  margin: 0;
  padding: 0;
  width: 100%;

  .ui {
    &.button {
      margin: 10px 0 0 0;
      height: 40px;
    }
    &.menu {
      width: 25%;
      margin: 10px 10px 0 0;
      .item,
      .text {
        width: 100%;
      }
    }
  }
}
