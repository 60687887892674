// ======== Theme Blue
// ------- site-nav
// ----------------
// ----- branding colors
// Secondary Darkest Green
$brand-green-02: #008202;
// Secondary Drk Green
$brand-green-01: #009412;
// Primary green
$brand-green: #00a652;
// Primary Light Green
$brand-green-light: #99e133;
// Light Grey
$brand-light-grey: #ebebeb;
// Grey
$brand-grey: #808184;
// Secondary Dark Grey
$brand-grey-01: #708f96;
// Secondary Blue
$brand-blue: #87bae3;

$site-nav-bg: $md-white;
$site-nav-toggle-color: $brand-green-01;
$site-nav-head-color: $md-grey-900;
$site-nav-head-border-color: $brand-green;
$site-nav-list-color: lighten($md-grey-900, 8%);
$site-nav-list-hover-color: $brand-green;

// ------ view-header
// ------------------
$view-header-bg: $site-nav-toggle-color;

/// MORE COMING SOON

/// ####### END #######
