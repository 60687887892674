.preview-modal {
  .ui.grid > .row {
    align-items: center;
  }

  &__flex {
    justify-content: space-between;
    width: 55%;
    align-items: center;

    div:nth-child(2):before {
      content: '.';
      vertical-align: text-bottom;
      padding-right: 8px;
    }

    div:nth-child(2):after {
      content: '.';
      vertical-align: text-bottom;
      padding-left: 8px;
    }
  }
}
