.ReactTable {
  .Select {
    &--multi .Select-input {
      margin-left: 0;
    }

    &-value {
      margin-bottom: 5px;
    }

    &.is-focused {
      z-index: 1;
    }
  }
  .Select-multi-value-wrapper {
    .Select-value {
      display: block;
    }
  }
}

// Semantic UI overwriting styled-ui, so overwriting Semantic
div[role='presentation'] p {
  margin-bottom: 16px;
}

button.delete {
  border: none;
  background: none;
}
