@import '../md-colors';

.order-photos-modal {
  &__form-field {
    padding: 14px;
  }
  .ui.label {
    padding: 0;
    background-color: transparent;

    & span:nth-child(2) {
      font-weight: normal;
    }
  }
  .ui.input,
  .ui.form textarea {
    margin: 10px 0 20px 0;
  }

  .ui.input > input {
    border-radius: 0;
    border-color: $md-black;
  }

  &__order-type {
    display: flex;
    flex-direction: column;

    .ui.label {
      margin-bottom: 10px;
    }

    .ui.radio:last-child {
      margin-bottom: 20px;
    }
  }
}
