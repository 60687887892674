.query-builder {
  background-color: #f6f6f6;
  border: 1px solid #dbdbdc;

  &__footer {
    margin: 0 20px 10px 20px;
    background-color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cts .button {
    width: 140px;
  }
}
