.alert-queue {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  right: 2%;
  height: auto;
  width: auto;
}
