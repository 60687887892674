.market-edit {
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.market-edit-one-column {
  @media (min-width: 800px) {
    margin-left: 13%;
  }
}
