@import './components/variables';

.app-wrapper {
  display: block;
  min-height: 100vh;
}

.content-container {
  width: 100%;
  height: 100vh;
  transform: translate(0, 0);
  position: relative;
  @media (min-width: 992px) {
    &.full {
      padding-left: 0;
    }
    padding-left: 20em;
    // always hide on desktop
    .menu-dropshadow {
      display: none !important;
    }
  }
  // show on only menu show + mobile view (for hiding navigation)
  .menu-dropshadow {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: $zindex-dropdown-backdrop;
  }
}
.p-margin {
  margin: 0px 35px 0px 35px;
}

.image-hidden {
  opacity: 0.3;
}

.site-head {
  height: 62px;
  background: $view-header-bg;
  padding: 1.2rem;
}

.view {
  .view-header,
  .view-content {
    padding: 1em 1.2rem;
  }
  .view-header {
    background: $view-header-bg;
    margin-top: -2px;
    padding-bottom: 4.4rem;
  }
  .view-content {
    margin-top: -4.2rem;
  }
  .view-header .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
  }
  // for full pages like signin, 404 etc.
  .view-content.view-pages {
    padding: 4rem;
    margin: 0;
    min-height: 100vh;
  }
}
@media (min-width: 768px) {
  .site-head,
  .view .view-header,
  .view .view-content {
    padding: 1.1em 2rem;
  }
  .view .view-header {
    padding-bottom: 4.4rem;
  }
  .view-header header {
    .title {
      font-size: 1.45rem;
    }
    .subtitle {
      font-size: $font-size-root;
    }
  }
}
.rt-tr-group--sortable {
  > div {
    flex: 1 0 auto;
    display: inline-flex;

    > div {
      padding: 7px 5px;
      flex: 100 0 auto;
      width: 100px;
    }
  }
}
.ReactTable {
  .-filters {
    .rt-th,
    .rt-td {
      overflow: visible;
    }
  }
  .Select-input {
    > input {
      border: none !important;
    }
  }
}

.ui.form .Select .Select-input input {
  border: none;
  padding: 0;
}

.view-content .card,
.card .table-responsive {
  overflow: visible;
}

.validation-error {
  color: red;
  font-weight: bold;
  font-size: 12px;
}

.validation-required {
  color: red;
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.featured-tooltip {
  position: relative;
  color: #808080;
  font-size: 11px;

  div {
    position: absolute;
    display: none;
    top: -2px;
    z-index: 10;
    min-width: 300px;
    font-weight: normal;
    background: white;
    left: 20px;
    border: 1px solid green;
    padding: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: -5px;
      transform: rotate(-225deg);
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-right: 1px solid green;
      border-bottom: 1px solid green;
    }
  }

  &:hover {
    div {
      display: block;
    }
  }
}

.file-btn {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    cursor: pointer;
  }
}

.featured {
  .react-autosuggest {
    &__container {
      width: 300px;
      position: absolute;
      bottom: 0;
      z-index: 10;
      left: 130px;

      > input {
        height: 35px;
      }
    }
  }
}

.reset-password {
  font-size: 20px;
}

.property-report {
  .ReactTable {
    .rt-td {
      white-space: normal;

      &:nth-child(4) {
        background-color: #ffccff;
      }
      &:nth-child(5) {
        background-color: #80ff80;
      }
    }
  }
}

.photo-orders-report,
.property-suggestions {
  .ReactTable {
    .rt-td {
      white-space: normal;
    }
  }
}

.negative-row {
  background-color: #ffccff;
}

.positive-row {
  background-color: #80ff80;
}

.date-picker {
  @media (max-width: 768px) {
    flex-direction: column;

    button.button {
      margin-top: 10px;
    }
  }

  &__item {
    img {
      width: 26px;
      min-width: 26px;
      border-top: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      padding: 4px;
      cursor: pointer;
    }
  }
}

.custom-input {
  .react-datepicker-wrapper {
    width: 32px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    .react-datepicker__input-container {
      width: 32px;
      border-top: 0;
      border-bottom: 0;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 0 4px 4px 0;

      img {
        max-width: 16px;
      }
    }
  }
}

.hyperlink {
  text-decoration: underline;
  color: var(--bs-green);
}
