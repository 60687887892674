.sortable-item {
  position: relative;
  overflow: hidden;
  width: 450px;

  img {
    cursor: pointer;
  }

  .image-selected {
    box-shadow: 0px 0px 0px 8px #2987cd;
  }

  @media (max-width: 520px) {
    width: 99%;
  }

  .sortable-item__actions {
    background-color: #e5e5e5;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 15px 15px 10px 15px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 250ms ease-in-out;
  }

  .sortable-item__index {
    float: right;
  }

  .buttons__container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 850ms ease-in-out;

    @media (max-width: 520px) {
      top: 40%;
    }
  }

  &:hover {
    .sortable-item__actions {
      opacity: 1;
      transform: translateY(0px);
    }
    .buttons__container {
      opacity: 1;
    }
  }
}

.sortable-checkbox {
  margin-top: 10px;
  margin-right: 10px;
}
