.condition {
  margin: 10px 10px 0 10px;
  width: 100%;
  .menu,
  .input {
    padding: 0;
    margin: 0 5px;
  }

  .menu:nth-child(2n - 1),
  .input {
    width: 20%;
  }

  .menu:nth-child(2) .text {
    width: 50px;
  }

  &__criteria-type,
  &__criteria-value {
    .text,
    input {
      width: 100%;
    }
  }

  &__error-message {
    margin: auto 10px;
    color: red;
    font-weight: bold;
  }
}
