@import '../variables';

.site-nav {
  border-right: 1px solid $md-grey-200;
  width: 20em;
  position: fixed;
  z-index: $zindex-navbar-fixed;
  top: 0;
  bottom: 0;
  transition: 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.05);
  background: $site-nav-bg;
  // for smaller devices and only activate upon clicking by hamburger menu.
  transform: translate(-100%, 0);
  &.mini {
    transform: translate(0, 0);
  }

  // only show on large devices.
  @media (min-width: 992px) {
    transform: translate(0, 0);
    &.mini {
      transform: translate(-95%, 0);
      &:hover {
        transform: translate(0, 0);
      }
    }
  }
  .nav-list-container {
    height: calc(100% - 4em);
  }
  .scrollarea .scrollbar-container {
    opacity: 0;
    &.vertical {
      width: 15px;
    }
  }
  .scrollarea .scrollbar-container:hover {
    background: transparent;
  }

  .nav-head {
    padding: 0.8rem 1rem;
    text-align: center;
    position: relative;
    height: 4.4em;
    margin-left: -1.5rem;
  }
  .nav-head svg > path {
    stroke: $site-nav-list-hover-color;
  }
  .nav-head .toggle-dot {
    width: 11px;
    height: 11px;
    border: 2px solid $site-nav-toggle-color;
    border-radius: 50%;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
    &.active {
      background: $site-nav-toggle-color;
    }
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 400%;
      height: 400%;
      box-sizing: border-box;
      margin-left: -150%;
      margin-top: -150%;
      border-radius: 100%;
      background-color: $site-nav-toggle-color;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    @keyframes pulse-ring {
      0% {
        transform: scale(0.33);
      }
      80%,
      100% {
        opacity: 0;
      }
    }
  }

  .nav-head a {
    color: $site-nav-head-color;
    font-weight: 700;
    > svg {
      position: relative;
      top: 1px;
    }
    > strong {
      display: inline-block;
      margin-left: 0.2rem;
      letter-spacing: 2.4px;
      text-shadow:
        -0.5px 0px 0 lighten($site-nav-head-color, 10%),
        0.5px 0px 0 lighten($site-nav-head-color, 10%);
    }
  }
  .nav-list-title {
    padding: 0.4rem 1.6rem;
    margin: 1.4rem 0;
    font-size: 70%;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 15px;
      height: 2px;
      bottom: 0;
      background: $site-nav-list-hover-color;
    }
  }

  .nav-list {
  }
  .nav-list li a {
    cursor: pointer;
    display: block;
    padding: 1.1rem 1.5rem;
    color: $site-nav-list-color;
    font-weight: 600;
    position: relative;
    .name {
      display: inline-block;
      margin-left: 1.5rem;
      text-transform: uppercase;
      font-size: 90%;
      transform: translate(0, 1.1px);
    }
    .icon-down {
      float: right;
      transform: translate(0, 0.4rem);
      transition: 0.15s ease-in;
    }
    &:hover {
      color: $site-nav-list-hover-color;
    }
    .icon-dashboard {
      color: $site-nav-list-hover-color !important;
    }
    .badge-chart.badge {
      background: $site-nav-list-hover-color;
    }
  }
  .nav-list li > a.active {
    font-weight: 700;
    color: $site-nav-list-hover-color;
    > .icon-down {
      transform: translate(0, 0.4rem) rotate(90deg);
    }
  }

  // inner drop
  .nav-list li.selected > a {
    font-weight: 700;
    color: $site-nav-list-hover-color;
    .icon-down {
      transform: translate(0, 0.4rem) rotate(-270deg);
    }
  }
  .nav-list li .inner-drop {
    font-size: 90%;
    padding-left: 2.85rem;
    background: inherit;
    box-shadow:
      inset 0 1px 1px 0px rgba(0, 0, 0, 0.05),
      inset 0 -1px 1px 0 rgba(0, 0, 0, 0.05);
  }
  .nav-list li .inner-drop a {
    padding: 0.9rem 1.5rem;
  }
  .nav-list li .inner-drop a:hover {
    color: $site-nav-list-hover-color;
  }
}
