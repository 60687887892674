.group {
  padding: 10px;
  .ui.menu,
  .ui.button {
    height: 40px;
    margin: 0 5px;
  }

  .group {
    margin: 10px 10px 0 10px;
    border: 1px solid #dbdbdc;
  }

  &__error-message {
    margin: auto 10px;
    color: red;
    font-weight: bold;
  }
}
