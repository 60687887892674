.drag-drop {
  &__text {
    padding-bottom: 16px;
  }
  &__button .MuiButton-label {
    font-weight: bold;
  }
}

.file-upload {
  padding: 16px 16px 0 16px;
  display: flex;
  gap: 20px;
  &__text {
    margin-top: 16px;
    > span {
      margin-right: 16px;
    }
  }
  &__select {
    max-width: 460px;
    margin-bottom: 20px;
  }
  &__button {
    font-weight: bold;
    padding: 16px 0 0 16px;
    position: absolute;
    right: 16px;
  }
  &__hide {
    text-align: right;
    margin-top: 58px;
  }
}

.file-details {
  &__container {
    padding: 16px;
    border: solid 1px black;
    border-radius: 4px;
    background-color: whitesmoke;
    overflow-y: scroll;
    max-height: 1000px;
  }
}

.file-properties {
  margin-bottom: 10px;
}

.file-photos {
  padding-top: 150px;
}
